import { atomWithStorage, selectAtom } from 'jotai/utils'

import {
  coreQuadrantStepsAtom,
  selectedClusterDataAtom,
} from '@features/core-quadrant/state/atoms'
import { reflectionsAtom } from '@features/core-quadrant/steps/reflection/atoms'

export const pitfallOptionsAtom = selectAtom(
  selectedClusterDataAtom,
  (selected) => selected?.pitfalls ?? [],
)

export const pitfallContentAtom = selectAtom(
  coreQuadrantStepsAtom,
  (steps) => steps?.pitfallStep,
)

export const pitfallReflectionContentAtom = selectAtom(
  reflectionsAtom,
  (reflections) => reflections.pitfall,
)

/**
 * This atom is used to store the pitfall reflection in local storage.
 */
export const pitfallReflectionInputAtom = atomWithStorage(
  'pitfall-reflection',
  '',
)
