import { atom } from 'jotai'
import { selectAtom } from 'jotai/utils'

import {
  allReflectionsAtom,
  coreQuadrantStepsAtom,
} from '@features/core-quadrant/state/atoms'

export const reflectionContentAtom = selectAtom(
  coreQuadrantStepsAtom,
  (steps) => steps?.reflectionStep,
)

export const reflectionsAtom = atom((get) => {
  const reflections = get(allReflectionsAtom)

  const findReflection = (id: string) => reflections.find((r) => r.id === id)

  const coreQuality = findReflection('67262170')
  const pitfall = findReflection('67262178')
  const allergy = findReflection('67262201')
  const challenge = findReflection('67262199')

  return {
    coreQuality,
    pitfall,
    allergy,
    challenge,
  }
})
