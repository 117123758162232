import { atomWithStorage, selectAtom } from 'jotai/utils'

import {
  coreQuadrantStepsAtom,
  selectedClusterDataAtom,
} from '@features/core-quadrant/state/atoms'
import { reflectionsAtom } from '@features/core-quadrant/steps/reflection/atoms'

export const challengeOptionsAtom = selectAtom(
  selectedClusterDataAtom,
  (selected) => selected?.challenges ?? [],
)

export const challengeContentAtom = selectAtom(
  coreQuadrantStepsAtom,
  (steps) => steps?.challengeStep,
)

export const challengeReflectionContentAtom = selectAtom(
  reflectionsAtom,
  (reflections) => reflections.challenge,
)

/**
 * This atom is used to store the challenge reflection in local storage.
 */
export const challengeReflectionInputAtom = atomWithStorage(
  'challenge-reflection',
  '',
)
