import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { match } from 'ts-pattern'
/**
 * API response for the core-quadrant feature.
 *
 * @param T - The data type
 * @param E - The error type
 */
import { z } from 'zod'

import {
  hasSkippedReflectionAtom,
  selectedAllergyAtom,
  selectedChallengeAtom,
  selectedClusterAtom,
  selectedCoreQualityAtom,
  selectedPitfallAtom,
} from '@features/core-quadrant/state/atoms'
import { allergyReflectionInputAtom } from '@features/core-quadrant/steps/allergy/atoms'
import { challengeReflectionInputAtom } from '@features/core-quadrant/steps/challenge/atoms'
import { coreQualityReflectionInputAtom } from '@features/core-quadrant/steps/core-quality/atoms'
import { pitfallReflectionInputAtom } from '@features/core-quadrant/steps/pitfall/atoms'

export type APIResponse<T, E extends Error = Error> =
  | {
      status: 'success'
      data: T
      error?: undefined
    }
  | {
      status: 'failure'
      data?: undefined
      error: E
    }

/**
 * All available steps in the core-quadrant feature.
 */
export const stepsSchema = z.enum([
  'cluster',
  'core-quality',
  'pitfall',
  'allergy',
  'challenge',
  'explore',
  'reflection',
  'result',
  'explore-core-quality',
  'explore-pitfall',
  'explore-allergy',
  'explore-challenge',
  'save',
  'next-step',
])

export type Steps = z.infer<typeof stepsSchema>
export const steps = stepsSchema.Values

type GroupedStep = {
  title: string
  stepNumber: number
  steps: Steps[]
}

type GroupedStepInputValues = {
  first: string[]
  second: string[]
  secondSkipped: boolean[]
  third: boolean[]
}

const FIRST_GROUP = {
  title: 'Byg din kernekvadrant',
  stepNumber: 1,
  steps: ['cluster', 'core-quality', 'pitfall', 'challenge', 'allergy'],
} satisfies GroupedStep

const SECOND_GROUP = {
  title: 'Reflekter ',
  stepNumber: 2,
  steps: [
    'reflection',
    'explore-core-quality',
    'explore-challenge',
    'explore-pitfall',
    'explore-allergy',
    'save',
  ],
} satisfies GroupedStep

const THIRD_GROUP = {
  title: 'Brug din kernekvadrant',
  stepNumber: 3,
  steps: ['next-step', 'result'],
} satisfies GroupedStep

export const activeStep = atomWithStorage<Steps>('activeStep', 'cluster')
export const downloadClicked = atomWithStorage<boolean>(
  'downloadClicked',
  false,
)

const getInputValueAtom = atom((get) => {
  // get input values
  const selectedAllergy = get(selectedAllergyAtom)
  const selectedChallenge = get(selectedChallengeAtom)
  const selectedCluster = get(selectedClusterAtom)
  const selectedCoreQuality = get(selectedCoreQualityAtom)
  const selectedPitfall = get(selectedPitfallAtom)

  const coreQualityReflection = get(coreQualityReflectionInputAtom)
  const pitfallReflection = get(pitfallReflectionInputAtom)
  const challengeReflection = get(challengeReflectionInputAtom)
  const allergyReflection = get(allergyReflectionInputAtom)
  const hasSkipped = get(hasSkippedReflectionAtom)

  const downloadClickedValue = get(downloadClicked)

  // return values split into coresponding groups
  return {
    first: [
      selectedCluster,
      selectedCoreQuality,
      selectedPitfall,
      selectedChallenge,
      selectedAllergy,
    ],
    second: [
      coreQualityReflection,
      pitfallReflection,
      challengeReflection,
      allergyReflection,
    ],
    secondSkipped: [hasSkipped],
    third: [downloadClickedValue],
  } satisfies GroupedStepInputValues
})

const calculateProgress = (stepInputValues: string[] | boolean[]): number => {
  // How many percent is each step equal to
  const percentagePerStep = 100 / stepInputValues.length

  // if type boolean
  if (stepInputValues.every((value) => typeof value === 'boolean')) {
    // Number of inputs that true
    const numberOfInputTrue = stepInputValues.filter(Boolean).length
    // Return calculated progress percentage based on input thats true
    return numberOfInputTrue * percentagePerStep
  }

  // Number of inputs that has a value
  const numberOfInputWithValue = stepInputValues.filter((x) => x !== '').length
  // Return calculated progress percentage based on input with values
  return numberOfInputWithValue * percentagePerStep
}

const createGroupAtom = (group: GroupedStep) =>
  atom((get) => {
    const active = get(activeStep)
    const inputValues = get(getInputValueAtom)
    const isCurrentGroup = group.steps.includes(active)
    const progress = match(group.stepNumber)
      .with(1, () => calculateProgress(inputValues.first))
      .with(2, () => {
        const calculatedProgress = calculateProgress(inputValues.second)
        return calculatedProgress > 0
          ? calculatedProgress
          : calculateProgress(inputValues.secondSkipped)
      })
      .with(3, () => calculateProgress(inputValues.third))
      .otherwise(() => 0)
    const isCompleted = progress >= 100

    return {
      ...group,
      progress,
      isCurrent: isCurrentGroup,
      isCompleted,
    }
  })

export const firstGroup = createGroupAtom(FIRST_GROUP)
export const secondGroup = createGroupAtom(SECOND_GROUP)
export const thirdGroup = createGroupAtom(THIRD_GROUP)
