import { atomWithStorage, selectAtom } from 'jotai/utils'

import {
  coreQuadrantStepsAtom,
  selectedClusterDataAtom,
} from '@features/core-quadrant/state/atoms'
import { reflectionsAtom } from '@features/core-quadrant/steps/reflection/atoms'

export const coreQualityOptionsAtom = selectAtom(
  selectedClusterDataAtom,
  (selected) => selected?.coreQualities ?? [],
)

export const coreQualityContentAtom = selectAtom(
  coreQuadrantStepsAtom,
  (steps) => steps?.coreQualityNuanceStep,
)

export const coreQualityReflectionContentAtom = selectAtom(
  reflectionsAtom,
  (reflections) => reflections.coreQuality,
)

/**
 * This atom is used to store the core quality reflection in local storage.
 */
export const coreQualityReflectionInputAtom = atomWithStorage(
  'core-quality-reflection',
  '',
)
