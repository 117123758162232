import { atomWithStorage, selectAtom } from 'jotai/utils'

import {
  coreQuadrantStepsAtom,
  selectedClusterDataAtom,
} from '@features/core-quadrant/state/atoms'
import { reflectionsAtom } from '@features/core-quadrant/steps/reflection/atoms'

export const allergyOptionsAtom = selectAtom(
  selectedClusterDataAtom,
  (selected) => selected?.allergies ?? [],
)

export const allergyContentAtom = selectAtom(
  coreQuadrantStepsAtom,
  (steps) => steps?.allergyStep,
)

export const allergyReflectionContentAtom = selectAtom(
  reflectionsAtom,
  (reflections) => reflections.allergy,
)

/**
 * This atom is used to store the allergy reflection in local storage.
 */
export const allergyReflectionInputAtom = atomWithStorage(
  'allergy-reflection',
  '',
)
