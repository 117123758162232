import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

import type { Clusters } from '@features/core-quadrant/api/step-flow/get-clusters'
import type { Guide } from '@features/core-quadrant/api/step-flow/get-guides'
import type { GuidesZip } from '@features/core-quadrant/api/step-flow/get-guides-zip'
import type { Reflections } from '@features/core-quadrant/api/step-flow/get-reflections'
import type { CoreQuadrantSteps } from '@features/core-quadrant/api/step-flow/get-steps'

/**
 * All guides content atom.
 * Data is fetched from DatoCMS and stored in the atom.
 *
 * @see {coreQuadrantApi.getGuides} for the method
 */
export const allGuidesAtom = atom<Guide[]>([])

/**
 * All guides file atom.
 * Data is fetched from DatoCMS and stored in the atom.
 *
 * @see {coreQuadrantApi.getGuidesZip} for the method
 */
export const guidesZipAtom = atom<GuidesZip>({ id: '', url: '' })

/**
 * All reflections' content atom.
 * Data are fetched from DatoCMS and stored in the atom.
 *
 * @see {getReflections} for the method
 */
export const allReflectionsAtom = atom<Reflections>([])

/**
 * All clusters content atom.
 * Data are fetched from DatoCMS and stored in the atom.
 *
 * @see {coreQuadrantApi.getClusters} for the method
 */
export const clustersAtom = atom<Clusters>([])

/**
 * All steps content atom.
 * Data are fetched from DatoCMS and stored in the atom.
 *
 * @see {coreQuadrantApi.getSteps} for the method
 */
export const coreQuadrantStepsAtom = atom<CoreQuadrantSteps | undefined>(
  undefined,
)

/**
 * The users' cluster selection atom
 */
export const selectedClusterAtom = atomWithStorage('cluster-selection', '')

/**
 * The users' core quality selection atom
 */
export const selectedCoreQualityAtom = atomWithStorage(
  'core-quality-selection',
  '',
)

/**
 * The users' pitfall selection atom
 */
export const selectedPitfallAtom = atomWithStorage('pitfall-selection', '')

/**
 * The users' allergy selection atom
 */
export const selectedAllergyAtom = atomWithStorage('allergy-selection', '')

/**
 * The users' challenge selection atom
 */
export const selectedChallengeAtom = atomWithStorage('challenge-selection', '')

/**
 * The data of the selected cluster
 *
 * Each cluster holds a set of qualities for each quadrant
 * This atom scopes the clusters to the selected core quality
 */
export const selectedClusterDataAtom = atom((get) => {
  const clusters = get(clustersAtom)
  const selectedCluster = get(selectedClusterAtom)

  return clusters.find((cluster) => cluster.title === selectedCluster)
})

/**
 * The input value of the users email address
 */
export const userEmailAtom = atomWithStorage('user-email', '')

/**
 * Whether the user has accepted Ledernes marketing terms
 */
export const hasAcceptedTermsAtom = atomWithStorage('has-accepted-terms', false)

/**
 * Whether the user has skipped the reflection section
 */
export const hasSkippedReflectionAtom = atomWithStorage(
  'has-skipped-reflection',
  false,
)
